
import { defineComponent, reactive, ref } from 'vue';
import AppContent from '../bases/AppContent.vue';
import ProgressStep from '@/bases/ProgressStep.vue';
import Button from '../bases/Button.vue';
import ModalMsg from '../bases/ModalMsg.vue';
import { GoogleAppsScript } from '../apis/googleScriptRun';
import '../assets/main.css';
import { useStore } from 'vuex';
import router from '@/router';

export default defineComponent({
  components: { AppContent, Button, ModalMsg, ProgressStep },
  setup() {
    const store = useStore();
    const googleAppsScript = new GoogleAppsScript(store.state.token);
    const status = ref<'nothing' | 'running' | 'success' | 'failure'>('nothing');
    const progressState = reactive({
      running: false,
      msg: '',
      max: 0,
      now: 0,
    });
    let resultURL = '';
    const target = store.state.parameter.ids[0];
    const templateType = store.state.parameter.templateType;

    const aggregate = async () => {
      progressState.running = true;
      status.value = 'running';
      if (googleAppsScript === null) {
        return;
      }
      const params = store.state.parameter;
      let methodName = '';
      let searchType: 'document' | 'presentation' = 'document';
      if (params.templateType === 'docs') {
        methodName = 'aggregateDocsTemplate';
        searchType = 'document';
      } else if (params.templateType === 'slide') {
        methodName = 'aggregateSlideV1Template';
        searchType = 'presentation';
      } else if (params.templateType === 'stickynote') {
        methodName = 'aggregateStickynote';
        searchType = 'presentation';
      } else {
        return;
      }
      const ids = await searchFile(params.ids[0], searchType);
      console.log(ids);
      const timer = setInterval(async () => {
        if ((await getProgress()) === false) {
          clearInterval(timer);
        }
      }, 1000);
      let res;
      if (methodName === 'aggregateStickynote') {
        res = await googleAppsScript.run(methodName, ids, confSlides.stickyNote); //eslint-disable-line
      } else {
        res = await googleAppsScript.run(methodName, ids);
      }
      clearInterval(timer);
      if (res) {
        resultURL = res;
        const currntResult = {
          url: res,
          type: params.templateType,
          id: params.ids[0],
          date: new Date().toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' }),
        };
        const now = localStorage.getItem('results') ?? '';
        if (now === '') {
          localStorage.setItem('results', JSON.stringify([currntResult]));
        } else {
          const ary = JSON.parse(now) as any[];
          if (10 < ary.push(currntResult)) {
            ary.shift();
          }
          const add = JSON.stringify(ary);
          localStorage.setItem('results', add);
        }
        status.value = 'success';
      } else {
        status.value = 'failure';
      }
      progressState.running = false;
    };
    const searchFile = async (
      folderId: string,
      mimeType: 'document' | 'presentation'
    ): Promise<string[]> => {
      const accessToken = store.state.token.token;
      const query = `'${folderId}' in parents and mimeType='application/vnd.google-apps.${mimeType}' and trashed=false`;
      const apiUrl = `https://www.googleapis.com/drive/v3/files?q=${query}&supportsAllDrives=true&includeItemsFromAllDrives=true&fields=files(id)`;
      //q="mimeType='application/vnd.google-apps.document'"`;
      // `https://www.googleapis.com/drive/v3/files?q='${folderId}'%20in%20parents%20and%20mimeType='application/vnd.google-apps.${mimeType}'`;
      const headers = new Headers({
        Authorization: `Bearer ${accessToken}`,
      });
      return fetch(apiUrl, { headers })
        .then((response) => response.json())
        .then((data) => {
          const fileIds = data.files.map((file: any) => file.id);
          return fileIds;
        })
        .catch((error) => console.error(error));
    };

    const getProgress = async () => {
      if (googleAppsScript === null) {
        return;
      }
      return await googleAppsScript
        .run('getUserCache', 'aggregate')
        .then((res: any) => {
          return JSON.parse(res);
        })
        .then((res: any) => {
          progressState.msg = res.msg;
          progressState.max = res.max;
          progressState.now = res.now;
        })
        .catch(() => {
          return false;
        });
    };
    const toSelectType = () => {
      router.push('/selectType');
    };
    const openResult = () => {
      window.open(resultURL, '_blank');
    };

    return {
      status,
      aggregate,
      progressState,
      openResult,
      toSelectType,
      target,
      templateType,
    };
  },
});
