
import { defineComponent, onMounted } from 'vue';
import AppContent from '../bases/AppContent.vue';
import ProgressStep from '../bases/ProgressStep.vue';
import '../assets/main.css';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
declare var google: any;

export default defineComponent({
  components: { AppContent, ProgressStep },
  setup() {
    const router = useRouter();
    const store = useStore();
    onMounted(() => {
      if (
        store.state.token.token !== null ||
        store.state.untill !== false ||
        Date.now() < store.state.untll
      ) {
        router.push('/inputUrl');
      }
    });
    const login = () => {
      // https://developers.google.com/identity/oauth2/web/reference/js-reference#TokenResponse
      const client = google.accounts.oauth2.initTokenClient({
        client_id: '1084441992477-u5u3bjicrgahi91ue7i9vocusqo5nrtk.apps.googleusercontent.com',
        // @smart10plate.com内のクライアントキー：'165758524339-qb8aa9leqdi99gfktea4oi9q0v3al3je.apps.googleusercontent.com',
        scope:
          //'https://www.googleapis.com/auth/drive
          'https://www.googleapis.com/auth/drive.readonly \
          https://www.googleapis.com/auth/drive.file \
          https://www.googleapis.com/auth/documents \
          https://www.googleapis.com/auth/spreadsheets \
          https://www.googleapis.com/auth/presentations \
          https://www.googleapis.com/auth/script.external_request \
          https://www.googleapis.com/auth/userinfo.email',
        callback: (response: any) => {
          console.log(response);
          const dt = Date.now();
          store.commit('setToken', {
            token: response.access_token,
            untill: dt + response.expires_in * 1000,
          });
          store.commit('setUserDomain', response.hd);
          const param = store.getters.getParameter;
          console.log(param);
          if (!param || param.action === 'create') {
            router.push('/inputUrl');
          } else if (param.action === 'open' && param.ids && !param.templateType) {
            router.push('/selectType');
          } else if (param.action === 'open' && param.ids && param.templateType) {
            router.push('execution');
          } else {
            router.push('/inputUrl');
          }
        },
      });
      client.requestAccessToken();
    };
    return {
      login,
    };
  },
});
