import { MinLogger } from 'min-logger';
import { useStore } from 'vuex';
import GoogleAppsScriptId from '../../../google.apps.script.id';
export { GoogleAppsScript };
const deployId = 'AKfycbzxZT2uXkl-ojf2wig1yQa0_mPMKSl24ccEr5p14utpIy07fGpUANU1kP47zraswFel0g';
//'1-F4i_sV-lAAo9tkIZvjJj_TlyxOum1Afn_KlspYNDjfqIN9goCf6LkpD';
//'AKfycbxejv9ot0u33xycqgPFpXZ5zeGc6P3DtKGSpn7ZSET0Ei2ihL-4zjfC6waVBkM970EGAg';

class GoogleAppsScript {
  private token;
  private store = useStore();
  private logger: MinLogger = this.store.state.logger;
  constructor(token: { token: string | null; untill: Date | false }) {
    this.token = token.token;
  }
  async run(functionName: string, ...args: [...any]): Promise<any> {
    if (!deployId) {
      throw new ReferenceError('Script ID is not defined');
    }
    return await fetch(`https://script.googleapis.com/v1/scripts/${deployId}:run`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
        Accept: 'application/json',
      },
      body: JSON.stringify({
        devMode: true,
        function: functionName,
        parameters: [...args],
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw new GASError(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        return new Promise((resolve, reject) => {
          if (res.done) {
            resolve(res.response.result);
          } else {
            this.logger.warn(
              useStore().getters.userDomain,
              `Google Apps Script Function Error on ${functionName}.`,
              `error-code: ${JSON.stringify(res.error.code)}. `,
              `detail: ${JSON.stringify(res.error.message)}`
            );
            reject(res);
          }
        });
      })
      .catch((e) => {
        console.log(this.logger);
        this.logger.error(
          this.store.state.userDomain,
          'Google Apps Script Unknown Communication Error',
          e
        );
        return false;
      });
  }
}

class GASError extends Error {
  private user = 'unknown user';
  constructor(e?: string) {
    super(e);
    this.name = new.target.name;
  }
}
