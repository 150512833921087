
import router from '@/router';
import { useStore } from 'vuex';
import { defineComponent, ref, computed } from 'vue';
import ModalMsg from '@/bases/ModalMsg.vue';
import Button from '@/bases/Button.vue';
import StackPanelResult from './StackPanelResult.vue';
declare var google: any;

export default defineComponent({
  components: { ModalMsg, Button, StackPanelResult },
  setup() {
    const store = useStore();
    const modal = ref(false);
    const panel = ref(false);
    const logger = store.state.logger;
    const isLogin = computed(() => {
      if (
        store.state.token.token === null ||
        store.state.untill === false ||
        Date.now() > store.state.untll
      ) {
        return false;
      } else {
        return true;
      }
    });
    const logoClick = () => {
      router.push('/');
    };
    const menuClick = () => {
      modal.value = true;
    };
    const sendLog = () => {
      store.state.logger.send();
    };
    const logout = () => {
      google.accounts.oauth2.revoke();
      store.commit('initialize');
      router.push('/');
      modal.value = false;
    };
    const showResults = () => {
      panel.value = true;
    };
    return {
      panel,
      isLogin,
      logger,
      modal,
      logoClick,
      menuClick,
      sendLog,
      showResults,
      logout,
    };
  },
});
