
import { defineComponent, onMounted, ref } from 'vue';
import AppContent from '../bases/AppContent.vue';
import ProgressStep from '@/bases/ProgressStep.vue';
import Button from '@/bases/Button.vue';
import '@/assets/main.css';
import '@/assets/stepProgress.css';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default defineComponent({
  components: { AppContent, Button, ProgressStep },
  setup() {
    onMounted(() => {
      if (
        store.state.token.token === null ||
        store.state.untill === false ||
        Date.now() > store.state.untll
      ) {
        router.push('/login');
      } else if (store.state.parameter && store.state.parameter.selectType) {
        router.push('/execution');
      }
    });
    const store = useStore();
    const router = useRouter();
    const selectType = ref<string | null>(null);
    const isValid = ref(true);
    const setParam = () => {
      if (selectType.value === null) {
        isValid.value = false;
        return;
      }
      const nowParam = store.getters.getParameter;
      nowParam.templateType = selectType.value;
      console.log(nowParam);
      store.commit('setParameter', JSON.stringify(nowParam));
      router.push('/execution');
    };
    const toInputUrl = () => {
      router.push('/inputUrl');
    };
    return {
      setParam,
      selectType,
      isValid,
      toInputUrl,
    };
  },
});
