
import { defineComponent, ref, onMounted } from 'vue';
import AppContent from '@/bases/AppContent.vue';
import ProgressStep from '@/bases/ProgressStep.vue';
import Button from '@/bases/Button.vue';
import '@/assets/main.css';
import '@/assets/stepProgress.css';
import { useStore } from 'vuex';
import router from '@/router';

export default defineComponent({
  components: { AppContent, Button, ProgressStep },
  setup() {
    const store = useStore();
    onMounted(() => {
      if (
        store.state.token.token === null ||
        store.state.untill === false ||
        Date.now() > store.state.untll
      ) {
        router.push('/login');
      } else if (
        store.state.parameter &&
        store.state.parameter.ids &&
        store.state.parameter.ids[0]
      ) {
        router.push('/selectType');
      }
    });
    const isValid = ref(true);

    const setParam = async () => {
      // validate url
      const para = document.getElementById('driveURL') as HTMLInputElement;
      //const reg = new RegExp('https://drive.google.com/drive/.*');
      // eslint-disable-next-line no-useless-escape
      const re =
        /^https:\/\/drive\.google\.com\/(drive\/(u\/\d+\/)?folders\/|file\/d\/|open\?id=)[a-zA-Z0-9_-]+(\/view)?/;
      const reg = new RegExp(re);
      if (!reg.test(para.value)) {
        isValid.value = false;
        return;
      }
      const id = para.value.split('/')[para.value.split('/').length - 1];
      store.commit('setParameter', JSON.stringify({ action: 'open', ids: [id] }));
      router.push('/selectType');
    };
    return {
      setParam,
      isValid,
    };
  },
});
