
import { defineComponent, ref } from 'vue';
import Button from '../bases/Button.vue';

export default defineComponent({
  setup: () => {
    const modal = ref(false);
    // watch localstorage and update the result
    const results = ref(JSON.parse(localStorage.getItem('results') ?? '[]'));
    window.onstorage = (event) => {
      if (event.key !== 'results') return;
      results.value = JSON.parse(event.newValue ?? '[]');
    };
    const onOpen = (url: string) => {
      window.open(url, '_blank');
    };
    return {
      results,
      modal,
      onOpen,
    };
  },
  components: { Button },
});
