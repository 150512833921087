import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-69159b34"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {} }
const _hoisted_2 = {
  key: 0,
  style: {"overflow-y":"scroll","height":"350px"}
}
const _hoisted_3 = { class: "resultDate" }
const _hoisted_4 = { class: "resultType" }
const _hoisted_5 = {
  colspan: "2",
  class: "resultUrl"
}
const _hoisted_6 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.results.length !== 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.results, (result) => {
            return (_openBlock(), _createBlock(_component_Button, {
              onClick: ($event: any) => (_ctx.onOpen(result.url)),
              key: result.id,
              class: "stack"
            }, {
              default: _withCtx(() => [
                _createElementVNode("table", null, [
                  _createElementVNode("tr", null, [
                    _createElementVNode("td", _hoisted_3, _toDisplayString(result.date), 1),
                    _createElementVNode("td", _hoisted_4, "テンプレートタイプ:" + _toDisplayString(result.type), 1)
                  ]),
                  _createElementVNode("tr", null, [
                    _createElementVNode("td", _hoisted_5, "フォルダID:" + _toDisplayString(result.id), 1)
                  ])
                ])
              ]),
              _: 2
            }, 1032, ["onClick"]))
          }), 128))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, "なし"))
  ]))
}