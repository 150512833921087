import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9de6df6a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal" }
const _hoisted_2 = { class: "infomations" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "window",
        style: _normalizeStyle(_ctx.styles)
      }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 4)
    ]),
    _createElementVNode("span", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.imgsrc,
        style: {"width":"130px"}
      }, null, 8, _hoisted_3)
    ])
  ], 64))
}